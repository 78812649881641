<template>
    <div class="cargoController-detail">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div class="cargoController-form">
            <div class="cargoController-form-groupCode" v-show="notEdit">门店编码：{{groupCode}}</div>
            <el-form ref="myForm" :model="myForm" :rules="rules">
                <div class="transverse-box">
                    <el-form-item v-if="!merchantFlag" label-width="120px" label="商家名称" prop="merchantId">
                        <el-select v-model="myForm.merchantId" placeholder="请选择" @change="merchantChange(myForm.merchantId)" filterable :disabled="cantEditMerchantId">
                            <el-option v-for="item in merchantList" :key="item.id" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="120px" label="门店名称" prop="groupName">
                        <el-input v-model="myForm.groupName" maxlength="10" :disabled="notEdit" placeholder="请输入">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="transverse-box">
                    <el-form-item label-width="120px" label="选择城市" prop="city">
                        <el-cascader :options="provinceList" v-model="myForm.city" @change="oncilckCity" :disabled="notEdit"
                            clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label-width="120px" label="区号编码" prop="areaCode">
                        <el-input v-model="myForm.areaCode" disabled>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="transverse-box">
                    <el-form-item label-width="120px" label="门店负责人" prop="chargeName">
                        <el-input v-model="myForm.chargeName" :disabled="notEdit" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label-width="120px" label="负责人电话" prop="chargePhone">
                        <el-input v-model="myForm.chargePhone" :disabled="notEdit" placeholder="请输入"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label-width="120px" label="门店序列号" v-show="notEdit">
                    <el-input v-model="myForm.code" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="门店关联货柜">
                    <el-select v-model="myForm.macList" placeholder="请选择" filterable multiple :disabled="cantEditMerchantId">
                        <el-option v-for="item in associatedContainerList" :key="item.id" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="详细位置" prop="address">
                    <el-input id="place" placeholder="请输入地址" maxlength="200" v-model="myForm.address"
                        :disabled="notEdit">
                    </el-input>
                </el-form-item>
                <!-- <div id="container"></div> -->
                <el-form-item label-width="120px" label="是否有广告牌">
                    <el-switch style="display: inline-block;padding-top:5px;" v-model="myForm.stateStr" active-color="#67C23A"
                        inactive-color="#F56C6C" active-text="是" inactive-text="否" :disabled="notEdit">
                    </el-switch>
                </el-form-item>
                <div v-if="myForm.stateStr">
                    <el-form-item label-width="120px" label="广告牌备注" prop="billboardRemarks">
                        <el-input maxlength="200" type="textarea" rows="6" show-word-limit placeholder="请输入"
                            v-model="myForm.billboardRemarks" :disabled="notEdit">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="submitBox" v-show="!notEdit">
                    <el-button type="primary" @click="submit()">提交</el-button>
                </div>
            </el-form>
        </div>

    </div>
</template>
<script>
    let validMobile = (rule, value, callback) => {
        let reg = /^1[3456789]\d{9}$/;
        if(value){
            if (!reg.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        }else {
            callback();
        }
        
    };
    import cabinetUtil from "@utils/cabinetUtil.js"
    import ruleVerification from "@utils/ruleVerification.js"
    export default {
        data() {
            return {
                map: '',
                notEdit: true,
                cantEditMerchantId: true,
                title: '新增门店',
                groupCode: '',
                myForm: {
                    groupName: '',
                    chargeName: '',
                    chargePhone: '',
                    areaCode: '',
                    code: '',
                    macList: [],
                    address: '',
                    city: '',
                    stateStr: false,
                    billboardRemarks: '',
                    // addressCoordinates: {
                    //     lng: '',
                    //     lat: '',
                    // },
                    merchantId: '',
                },
                associatedContainerList: [],
                provinceList: [],
                selsectMacList: [],
                merchantList: [],
                merchantFlag: false,
                rules: {
                    groupName: [{
                        required: true,
                        message: "请输入门店名称",
                        trigger: ['change']
                    }],
                    areaCode: [{
                        required: true,
                        message: "请输入区号编码",
                        trigger: ['change']
                    }],
                    address: [{
                        required: true,
                        message: "请输入详细信息",
                        trigger: ['change']
                    }],
                    city: [{
                        required: true,
                        message: "请选择城市",
                        trigger: ['change']
                    }],
                    billboardRemarks: [{
                        required: true,
                        message: "输入广告备注",
                        trigger: ['change']
                    }],
                    merchantId: [{
                        required: true,
                        message: "请选择商家名称",
                        trigger: ['change']
                    }],
                    chargePhone: [{
                        required: false,
                        trigger: ['change'],
                        validator: validMobile,
                    }],
                }

            };
        },
        activated() {
            console.log("groupManagement-detail created!!");
            if (this.$route.params.type == "show") {
                this.notEdit = true;
            } else {
                this.notEdit = false;
            }
            if(this.$route.params.type == "add") {
                this.cantEditMerchantId = false;
            }else {
                this.cantEditMerchantId = true;
            }
            this.merchantFlag = this.getLoginType('loginType');
            console.log('====================:', this.notEdit)
            this.title = ["添加门店", "编辑门店", "门店详情"][{
                add: 0,
                edit: 1,
                show: 2
            } [this.$route.params.type]];
            this.myForm = {
                groupName: '',
                chargeName: '',
                chargePhone: '',
                areaCode: '',
                code: '',
                macList: [],
                address: '',
                city: '',
                billboardRemarks: '',
                stateStr: false,
                // addressCoordinates: {
                //     lng: '',
                //     lat: '',
                // },
                merchantId: '',
            };
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields();
                }
            })
            if (!this.merchantFlag) {
                cabinetUtil.getMerchantList().then(res => {
                    this.merchantList = res;
                })
            }
            this.getCityData();
            // 商家登录，请求旗下货柜数据
            this.merchantFlag = this.getLoginType();
            if(this.merchantFlag) {
                let dto = {
                    merchantId: this.cache.getLS('userInfo').merchantId
                }
                this.getContainerList(dto);
            }
            if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
                this.getDeatil();
            }
            // this.initMap();

        },
        // 离开页面时初始化form表达，防止货柜查看的门店货柜数据较多时，再次进入详情页 页面崩溃的问题
        deactivated() {
          this.myForm = {
                groupName: '',
                chargeName: '',
                chargePhone: '',
                areaCode: '',
                code: '',
                macList: [],
                address: '',
                city: '',
                billboardRemarks: '',
                stateStr: false,
                // addressCoordinates: {
                //     lng: '',
                //     lat: '',
                // },
                merchantId: '',
            };  
        },
        methods: {
            submit() {
                let cancelSelsectMacList = [];
                let newSelsectMacList = [];
                this.$refs["myForm"].validate((valid) => {
                    if (!valid) {
                        return;
                    } else {
                        let dto = {
                            groupCode: this.groupCode,
                            groupName: this.myForm.groupName,
                            chargeName: this.myForm.chargeName,
                            chargePhone: this.myForm.chargePhone,
                            areaCode: this.myForm.areaCode,
                            code: this.myForm.code,
                            macList: this.myForm.macList,
                            address: this.myForm.address,
                            province: this.myForm.city[0],
                            city: this.myForm.city[1],
                            isHasBillboard: this.myForm.stateStr ? 1 : 2,
                            // longitude: this.myForm.addressCoordinates.lng,
                            // latitude: this.myForm.addressCoordinates.lat,
                        }

                        if (!this.merchantFlag) {
                            dto.merchantId = this.myForm.merchantId;
                        }else {
                            dto.merchantId = this.cache.getLS('userInfo').merchantId;
                        }

                        this.selsectMacList.forEach(item => {
                            if (this.myForm.macList.indexOf(item) == -1 && cancelSelsectMacList.indexOf(
                                    item) == -1) {
                                //取消的mac
                                cancelSelsectMacList.push(item);
                            }
                        })
                        this.myForm.macList.forEach(item => {
                            if (this.selsectMacList.indexOf(item) == -1 && newSelsectMacList.indexOf(
                                    item) == -1) {
                                //新选中的mac
                                newSelsectMacList.push(item);
                            }
                        })
                        if (this.myForm.stateStr) {
                            dto.billboardRemarks = this.myForm.billboardRemarks;
                        }
                        let message = '添加成功！'
                        dto.macLossList = [];
                        if (this.$route.params.type == 'edit') {
                            message = '修改成功！'
                            dto.groupId = this.$route.params.id;
                            dto.macLossList = cancelSelsectMacList.concat(newSelsectMacList);
                        }
                        this.post('/mall-service/counter_group/save_one', dto, {
                            isUseResponse: true,
                        }).then(res => {
                            this.$message({
                                showClose: true,
                                message: message,
                                type: "success"
                            });
                            this.$back();
                        })

                    }
                })
            },
            oncilckCity(e) {
                this.myForm.areaCode = e[1];
                //根据城市code获取序列号
                this.get('/mall-service/counter_group/get_serializable_num?cityCode=' + e[1])
                    .then(res => {
                        this.myForm.code = res;
                        this.groupCode = this.myForm.areaCode + res;
                    })
            },
            getCityData() {
                this.get('/landlord-service/base/getTree').then(res => {
                    this.provinceList = res;
                })
            },
            getContainerList(dto) {
                dto = dto ? dto : {};
                this.associatedContainerList = [];
                this.post('/mall-service/v1/counter/getList', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0 && res.data.data && res.data.data.length > 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            let item = res.data.data[i];
                            this.associatedContainerList.push({
                                label: item.groupName ? item.mac + '(' + item.groupName + ')' : item
                                    .mac,
                                value: item.mac,
                                id: item.id,
                            })
                        }
                    }

                })
            },
            getDeatil() {
                this.get('/mall-service/counter_group/get_detail?groupId=' + this.$route.params
                    .id).then(res => {
                    this.groupCode = res.groupCode;
                    this.myForm.groupName = res.groupName;
                    this.myForm.chargeName = res.chargeName;
                    this.myForm.chargePhone = res.chargePhone;
                    this.myForm.areaCode = res.areaCode;
                    this.myForm.code = res.code;
                    this.myForm.address = res.address;
                    this.myForm.macList = res.macList;
                    this.myForm.merchantId = res.merchantId;
                    this.selsectMacList = res.macList;
                    this.province = res.province;
                    let array = [];
                    array.push(res.province, res.city);
                    this.myForm.city = array;
                    this.myForm.billboardRemarks = res.billboardRemarks;
                    this.myForm.stateStr = res.isHasBillboard == 1 ? true : false;

                    // this.myForm.addressCoordinates.lat = res.latitude;
                    // this.myForm.addressCoordinates.lng = res.longitude;
                    // let center = new qq.maps.LatLng(res.latitude, res.longitude);
                    // this.map = new qq.maps.Map(document.getElementById("container"), {
                    //     center: center,
                    //     zoom: 18
                    // });
                    // this.setMarker(center);
                    // //编辑时initMap中的监听地图点击事件不生效  于是这里重新监听一遍
                    // this.addListener();

                    let obj = {
                      merchantId: res.merchantId
                    }
                    this.getContainerList(obj);

                })
            },
            // initMap() {
            //     let _this = this;
            //     if (this.$route.params.type == 'add') {
            //         _this.map = new qq.maps.Map(document.getElementById("container"), {
            //             center: new qq.maps.LatLng(39.916527, 116.397128),
            //             zoom: 18
            //         });
            //     }
            //     //实例化自动完成
            //     var ap = new qq.maps.place.Autocomplete(document.getElementById('place'));
            //     var keyword = "";
            //     var selectIndex = "";
            //     //添加监听事件
            //     qq.maps.event.addListener(ap, "confirm", function (res) {
            //         keyword = res.value;
            //         selectIndex = res.index;
            //         searchService.search(keyword);
            //     });
            //     //调用Poi检索类。用于进行本地检索、周边检索等服务。
            //     var searchService = new qq.maps.SearchService({
            //         complete: function (results) {
            //             if (results.type === "CITY_LIST") {
            //                 searchService.setLocation(results.detail.cities[0].cityName);
            //                 searchService.search(keyword);
            //                 return;
            //             }
            //             var pois = results.detail.pois;
            //             var latlngBounds = new qq.maps.LatLngBounds();
            //             for (var i = 0, l = pois.length; i < l; i++) {
            //                 if (l >= selectIndex) {
            //                     if (selectIndex == i) {
            //                         _this.myForm.address = keyword;
            //                         // 返回的pois数据条目大于等于选择的下标时
            //                         var poi = pois[i];
            //                         _this.myForm.addressCoordinates = poi.latLng;
            //                         latlngBounds.extend(poi.latLng);
            //                         _this.setMarker(poi.latLng);
            //                         break
            //                     }
            //                 } else if (keyword == pois[i].name) {
            //                     _this.myForm.address = keyword;
            //                     // 当返回的pois数据条目小于选择的下标时
            //                     var poi = pois[i];
            //                     console.log('poi.latLng:', poi.latLng)
            //                     _this.myForm.addressCoordinates = poi.latLng;
            //                     latlngBounds.extend(poi.latLng);
            //                     _this.setMarker(poi.latLng);
            //                     break
            //                 }
            //             }
            //             _this.map.fitBounds(latlngBounds);
            //         }
            //     });
            //     _this.addListener();
            // },
            // addListener() {
            //     let _this = this;
            //     // 监听点击事件
            //     qq.maps.event.addListener(_this.map, "click", function (event) {
            //         let latitude = event.latLng.getLat();
            //         let longitude = event.latLng.getLng();
            //         let center = new qq.maps.LatLng(latitude, longitude);
            //         _this.setMarker(center);
            //         let geocoder = new qq.maps.Geocoder({
            //             complete: function (result) {
            //                 _this.myForm.address = result.detail.address; //腾讯地图选择
            //                 _this.myForm.addressCoordinates = result.detail.location; //选择的位置坐标
            //             }
            //         });
            //         let coord = new qq.maps.LatLng(latitude, longitude);
            //         geocoder.getAddress(coord);
            //     });
            // },
            // setMarker(center) {
            //     let _this = this;
            //     let _marker = _this.cache.getR("appFormMarker");
            //     if (_marker) {
            //         // 清除标记
            //         _marker.setMap(null);
            //     }
            //     let marker = new qq.maps.Marker({
            //         position: center,
            //         map: _this.map
            //     });
            //     _this.cache.setR("appFormMarker", marker);
            // },
            merchantChange(val) {
                let dto = {
                    merchantId: val
                }
                this.getContainerList(dto);
            }
        }
    };
</script>
<style lang="scss" scoped>
    .cargoController-detail {
        min-width: 1250px;
    }

    .cargoController-form {
        padding: 0px 90px;
    }

    .cargoController-form-groupCode {
        font-size: 14px;
        color: #999;
        padding-left: 56px;
        padding-bottom: 20px;
    }
    .transverse-box{
        display: flex;
        .el-form-item{
            width: 50%;
        }
    }
    .submitBox {
        text-align: center;
        padding-bottom: 20px;
        .el-button{
            width: 180px;
        }
    }

    .el-switch {
        line-height: 17px;
    }

    // #container {
    //     width: 92%;
    //     height: 400px;
    //     margin-left: 121px;
    // }
</style>