var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cargoController-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cargoController-form" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notEdit,
                  expression: "notEdit"
                }
              ],
              staticClass: "cargoController-form-groupCode"
            },
            [_vm._v("门店编码：" + _vm._s(_vm.groupCode))]
          ),
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "div",
                { staticClass: "transverse-box" },
                [
                  !_vm.merchantFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "120px",
                            label: "商家名称",
                            prop: "merchantId"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                disabled: _vm.cantEditMerchantId
                              },
                              on: {
                                change: function($event) {
                                  _vm.merchantChange(_vm.myForm.merchantId)
                                }
                              },
                              model: {
                                value: _vm.myForm.merchantId,
                                callback: function($$v) {
                                  _vm.$set(_vm.myForm, "merchantId", $$v)
                                },
                                expression: "myForm.merchantId"
                              }
                            },
                            _vm._l(_vm.merchantList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "门店名称",
                        prop: "groupName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          disabled: _vm.notEdit,
                          placeholder: "请输入"
                        },
                        model: {
                          value: _vm.myForm.groupName,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "groupName", $$v)
                          },
                          expression: "myForm.groupName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "transverse-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "选择城市",
                        prop: "city"
                      }
                    },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.provinceList,
                          disabled: _vm.notEdit,
                          clearable: ""
                        },
                        on: { change: _vm.oncilckCity },
                        model: {
                          value: _vm.myForm.city,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "city", $$v)
                          },
                          expression: "myForm.city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "区号编码",
                        prop: "areaCode"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.myForm.areaCode,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "areaCode", $$v)
                          },
                          expression: "myForm.areaCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "transverse-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "门店负责人",
                        prop: "chargeName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.notEdit, placeholder: "请输入" },
                        model: {
                          value: _vm.myForm.chargeName,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "chargeName", $$v)
                          },
                          expression: "myForm.chargeName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "负责人电话",
                        prop: "chargePhone"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.notEdit, placeholder: "请输入" },
                        model: {
                          value: _vm.myForm.chargePhone,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "chargePhone", $$v)
                          },
                          expression: "myForm.chargePhone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notEdit,
                      expression: "notEdit"
                    }
                  ],
                  attrs: { "label-width": "120px", label: "门店序列号" }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.myForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "code", $$v)
                      },
                      expression: "myForm.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "门店关联货柜" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        filterable: "",
                        multiple: "",
                        disabled: _vm.cantEditMerchantId
                      },
                      model: {
                        value: _vm.myForm.macList,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "macList", $$v)
                        },
                        expression: "myForm.macList"
                      }
                    },
                    _vm._l(_vm.associatedContainerList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "详细位置",
                    prop: "address"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      id: "place",
                      placeholder: "请输入地址",
                      maxlength: "200",
                      disabled: _vm.notEdit
                    },
                    model: {
                      value: _vm.myForm.address,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "address", $$v)
                      },
                      expression: "myForm.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "是否有广告牌" } },
                [
                  _c("el-switch", {
                    staticStyle: {
                      display: "inline-block",
                      "padding-top": "5px"
                    },
                    attrs: {
                      "active-color": "#67C23A",
                      "inactive-color": "#F56C6C",
                      "active-text": "是",
                      "inactive-text": "否",
                      disabled: _vm.notEdit
                    },
                    model: {
                      value: _vm.myForm.stateStr,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "stateStr", $$v)
                      },
                      expression: "myForm.stateStr"
                    }
                  })
                ],
                1
              ),
              _vm.myForm.stateStr
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "120px",
                            label: "广告牌备注",
                            prop: "billboardRemarks"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "200",
                              type: "textarea",
                              rows: "6",
                              "show-word-limit": "",
                              placeholder: "请输入",
                              disabled: _vm.notEdit
                            },
                            model: {
                              value: _vm.myForm.billboardRemarks,
                              callback: function($$v) {
                                _vm.$set(_vm.myForm, "billboardRemarks", $$v)
                              },
                              expression: "myForm.billboardRemarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }